import React from "react";

function Faq() {
  return (
    <>
      <div className="row pt-5" style={{ backgroundColor: "white" }}>
        <center>
          <p
            style={{
              fontSize: 90,
              color: "#1a6e60",
              fontFamily: "black-mango",
              padding: 0,
            }}
            className="faq"
          >
            Frequently Asked Questions
          </p>
        </center>
      </div>
      <div className="row" style={{ backgroundColor: "white", paddingTop:50 }}>
        <div className="col-md-6 offset-md-3 pb-5" style={{ color: "black" }}>
          <div className="faq-outer-div">
            <div className="faq-left-text">
              <p className="padding-5">Is Fitreat.ai completely free to use?</p>
            </div>
            <div className="faq-inner-div-plus">+</div>
          </div>
          <div className="faq-outer-div">
            <div className="faq-left-text">
              <p className="padding-5">
                What is the revenue model for Fitreat.ai?
              </p>
            </div>
            <div className="faq-inner-div-plus">+</div>
          </div>
          <div className="faq-outer-div">
            <div className="faq-left-text">
              <p className="padding-5">
                Are there certified dietitians collaborating with Fitreat.ai?
              </p>
            </div>
            <div className="faq-inner-div-plus">+</div>
          </div>
          <div className="faq-outer-div">
            <div className="faq-left-text">
              <p className="padding-5">
                How does Fitreat.ai create complimentary diet plans?
              </p>
            </div>
            <div className="faq-inner-div-plus">+</div>
          </div>
          <div className="faq-outer-div">
            <div className="faq-left-text">
              <p className="padding-5">
                What makes Fitreat more sustainable choice
                compared to other apps or dietitians?
              </p>
            </div>
            <div className="faq-inner-div-plus">+</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
