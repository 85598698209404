import React from "react";
import Heading from "../Heading";
import CardContainer from "../CardContainer";
import lift from "../../assets/lift_w.png";
import s1 from "../../assets/s3.png";
import s2 from "../../assets/s2_w.png";
const Variants = () => {
  const data = [
    {
      image: lift,
      heading: "Elevator Switch",
      text: "Helix smart switches provide versatility to blend in various environment such as elevators.",
    },
    {
      image: s1,
      heading: "Air Touch",
      text: "Air touch is the unique feature to helix smart switches, which allows you to control them buy bringing your hand in close proximity.",
    },
    {
      image: s2,
      heading: "Wi-Fi Enabled",
      text: "Helix smart switches are internet enabled smart switch which can be control over the internet",
    },
  ];
  return (
    <div id='coverAllNeeds'
      className="row py-3 VariantContainer"
      style={{
        background: "linear-gradient(180deg, #131313 70%, #FFFFFF 30%)",
        padding: 0,
        margin: 0,
        color: "white",
        textAlign: "center",
      }}
    >
      <Heading
        
        heading="All you don't need is a Touch"
        tagline="Built for your Ease"
      />
      <CardContainer cardList={data} />
    </div>
  );
};

export default Variants;
