import React from "react";

export default function JobHeader(props) {
  return (
    <div className="row" style={{ padding: 0, margin: 0 }}>
      <div className="col-xl-6 leftJobHead" style={{ padding: 0, margin: 0 }}>
        <div className="layer">
          <div
            className="insideLeft"
            style={{ marginLeft: 25, marginRight: 15 }}
          >
            <h1
              style={{
                color: "white",
                fontSize: "3rem",
                fontFamily: "",
                fontWeight: 500,
              }}
            >
              {props.title}
            </h1>
            <p
              style={{
                fontSize: "1rem",
                fontFamily: "Marcellus",
                padding: "0px",
              }}
            >
              {props.roleType}
            </p>
            <div></div>
            <a href="/" style={{ textDecoration: "none" }}>
              <button
                // onClick={() => {
                //     console.log("hii", document.getElementById("coverAllNeeds"));
                //     document
                //         .getElementById("coverAllNeeds")
                //         .scrollIntoView({ behavior: "smooth" }, true);
                // }}
                className="button"
                style={{ minWidth: "10em" }}
              >
                <span style={{ fontFamily: "Marcellus" }}>Explore</span>
                <span>
                  <b>&gt;</b>
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 job-right"
        style={{ padding: 0, margin: 0 }}
      ></div>
    </div>
  );
}
