import React from 'react';

const ProdTestCard = props => {
    return (
        <div
            style={{
                backgroundImage: `url(${props.cover})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%"
            }}>
            <div
                style={{
                    backgroundColor: "rgba(26, 19, 19,.5)",
                    color: 'white',
                    textAlign: "start",
                    width: "100%",
                    padding: "10% 5% 10% 2%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-end"
                }}
            >
                <div className="pb-2">
                    <div className='px-4' style={{ fontSize: "1.2rem",fontFamily:'Marcellus' }}>{props.heading}</div>
                    <div className='px-4' style={{ fontSize: "1.5rem",fontFamily:'Light' }}>{props.desc}</div>
                </div>
                <button
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "none",
                        backgroundColor: "transparent",
                        width: "100%",
                        fontSize: "1.5em",
                        color: "white",
                        paddingLeft:'22px'
                    }}
                >
                    <a style={{textDecoration:'none' ,color:'white'}} href='/products'><span>Explore</span></a>
                    <a style={{textDecoration:'none' ,color:'white'}} href='/products'><span class="material-icons-outlined">arrow_forward_ios</span></a>
                </button>
            </div>
        </div>
    );
};

export default ProdTestCard;