import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import { WorldMap } from "react-svg-worldmap";

// Us
// Canada
// Uk
// Ireland
// Autralia
// Saudi Arabia
// Dubai
// Thialand

const data = [
  { country: "IN", value: "IN" },
  { country: "US", value: "US" },
  { country: "CA", value: "CA" },
  { country: "GB", value: "GB" },
  { country: "IE", value: "IE" },
  { country: "SA", value: "SA" },
  { country: "TH", value: "TH" },
  { country: "AE", value: "AE" },
];

const Map = () => {
  const [flagXXL, setFlagXXL] = useState(false);
  const [flagXL, setFlagXL] = useState(false);
  const [flaglg, setFlagLg] = useState(false);
  const [flagSm, setFlagSm] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", sizeHandler);
    // document.getElementById('world-map-xl').addEventListener('resize',sizeHandler);
    sizeHandler();
  });
  const sizeHandler = (e) => {
    if (window.screen.width > 1213) {
      console.log("inside greater than 1213", window.screen.width);
      setFlagXL(true);
      setFlagLg(true);
      setFlagSm(true);
      setFlagXXL(false);
    } else if (window.screen.width <= 1212 && window.screen.width >= 652) {
      setFlagXXL(true);
      setFlagLg(true);
      setFlagSm(true);
      setFlagXL(false);
      console.log("xl ->", flagXL);
      console.log("xxl ->", flagXXL);
    } else if (window.screen.width <= 651 && window.screen.width >= 492) {
      setFlagXL(true);
      setFlagXXL(true);
      setFlagSm(true);
      setFlagLg(false);
      console.log("in lg case ==", flaglg);
    } else if (window.screen.width <= 491 && window.screen.width >= 320) {
      setFlagXXL(true);
      setFlagXL(true);
      setFlagLg(true);
      setFlagSm(false);
    }
  };
  return (
    <div
      className="row"
      style={{
        backgroundColor: "#141414",
        textAlign: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <div className="col-xl-12 col-lg-12 col-sm-12 col-12" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <Heading
          heading="Reaching your Doorstep Soon"
          tagline="Locations we have worked on"
        />
        <div id="world-map-xxl" hidden={flagXXL}>
          <WorldMap
            color="#34b6dd"
            size="xxl"
            frame={false}
            data={data}
            backgroundColor="#141414"
            borderColor="white"
          />
        </div>
        <div id="world-map-xl" hidden={flagXL}>
          <WorldMap
            color="#34b6dd"
            size="xl"
            frame={false}
            data={data}
            backgroundColor="#141414"
            borderColor="white"
          />
        </div>

        <div id="world-map-lg" hidden={flaglg}>
          <WorldMap
            color="#34b6dd"
            size={"lg"}
            frame={false}
            data={data}
            backgroundColor="#141414"
            borderColor="white"
          />
        </div>
        <div id="world-map-sm" hidden={flagSm}>
          <WorldMap
            color="#34b6dd"
            size={"sm"}
            frame={false}
            data={data}
            backgroundColor="#141414"
            borderColor="white"
          />
        </div>
      </div>
    </div>
  );
};

export default Map;
