import React from "react";
import Heading from "./Heading";

const Contact = () => {
  return (
    <div
      className="row pb-5"
      style={{ textAlign: "center", padding: 0, margin: 0, color: "black" }}
    >
      <Heading heading="Skip the Hassle" tagline="Get your Work done Now" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="/contact-us" style={{ textDecoration: "none" }}>
          
          <button className="button" style={{ minWidth: "15em" }}>
            <span style={{fontFamily:'Marcellus'}}>Get an Instant Quote</span>
            <span>
              {/* <b>&gt;</b> */}
              <i className="arrow right-arrow"></i>
            </span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default Contact;
