import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ProductHeader from "../components/ProductPageComp/ProductHeader";
import About from "../components/ProductPageComp/About";
import ConnectivityBar from "../components/ProductPageComp/ConnectivityBar";
import ReliabiltyBann from "../components/ProductPageComp/ReliabiltyBann";
import FloatingPhone from "../components/ProductPageComp/FloatingPhone";
import Variants from "../components/ProductPageComp/Variants";
import { TabTitle } from "./DynamicTitle";

const OurProducts = () => {
  TabTitle("Products | HSL");
  return (
    <>
      <Nav textDecoProduct={'underline'} />
      <div className="container-fluid" style={{ padding: 0 }}>
        <ProductHeader />
        <About />
        <ConnectivityBar />
        <ReliabiltyBann />
        <FloatingPhone />
        <Variants />
      </div>
      <Footer />
    </>
  );
};

export default OurProducts;
