import React from "react";
import Heading from "../Heading";
import pcard1 from "../../assets/card1.png";
import pcard2 from "../../assets/card2.png";
import pcard3 from "../../assets/card3.png";
import Card from "../HompageComp/Card";
const OemServices = () => {
  const data = [
    {
      image: pcard1,
      heading: "3D Prototyping",
      text: "Convert your ideas to reality, with a one stop solution curated for specially for you. Available in both ABS & PLA, with varying strength and customization.",
    },
    {
      image: pcard2,
      heading: "PCB Design",
      text: "Turn your breadboard to a PCB at an affordable price & customization is no longer a dream. Services from designing to printing PCB's with no minimum order guarantee.",
    },
    {
      image: pcard3,
      heading: "Research & Development",
      text: "Get any product reveres engineered or turn your innovative ideas to reality with our Research and Development team specially catering solutions for your business.",
    },
  ];
  return (
    <div
    id="coverAllNeeds"
      className="row p-5"
      style={{ padding: 0, margin: 0, textAlign: "center", color: "black" }}
    >
      <Heading
        heading="Tackle all your Needs"
        tagline="Stack of Services you can't Resist"
      />
      <div
        className="row"
        
        style={{
          paddingTop: "5%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {data.map((item, index) => (
          <Card
            key={index}
            image={item.image}
            heading={item.heading}
            data={item.text}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default OemServices;
