import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ServiceHeader from "../components/AIPageComp/ServiceHeader";
import SolutionList from "../components/AIPageComp/SolutionList";
import WorkExamples from "../components/AIPageComp/WorkExamples";
import Contact from "../components/Contact";
import { TabTitle } from "./DynamicTitle";
const AiSolutions = () => {
  TabTitle("AI Analytics | HSL");
  return (
    <>
      <Nav />
      <div>
        <ServiceHeader />
        <SolutionList />
        <WorkExamples />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default AiSolutions;
