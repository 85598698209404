import React from "react";
import Heading from "../Heading";
import dashboard from "../../assets/dashboard.png";
import tools from "../../assets/tool.png";
import Cvision from "../../assets/cvision.png";
import Card from "../HompageComp/Card";
const SolutionList = () => {
  const data = [
    {
      image: dashboard,
      heading: "Dashboard",
      text: "Custom dashboards curated for individual businesses displaying key performance indicators relevant to a particular objective or business process",
    },
    {
      image: tools,
      heading: "AI based Tools",
      text: "Tools for data analysis, machine learning, and mathematical modeling optimized for individual business objects and key performance indicators.",
    },
    {
      image: Cvision,
      heading: "Computer Vision",
      text: "Leverage the potential of AI to derive meaningful information from static media such as images, videos, and more.",
    },
  ];
  return (
    <div
    id="coverAllNeeds"
      className="row p-5"
      style={{ padding: 0, margin: 0, textAlign: "center", color: "black" }}
    >
      <Heading
        heading="Tackle all your Needs"
        tagline="Stack of Services you can't Resist"
      />
      <div
        className="row"
        
        style={{
          paddingTop: "5%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {data.map((item, index) => (
          <Card
            key={index}
            image={item.image}
            heading={item.heading}
            data={item.text}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default SolutionList;
