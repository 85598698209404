import React from "react";
import Heading from "../Heading";
import Work1 from "../../assets/ai1.jpg";
import Work2 from "../../assets/ai2.jpg";

const WorkExamples = () => {
  return (
    <div
      className="row p-5"
      style={{
        padding: 0,
        margin: 0,
        textAlign: "center",
        color: "white",
        backgroundColor: "rgb(19,19,19)",
      }}
    >
      <div className="col" style={{ padding: 0, margin: 0 }}>
        <Heading heading="Have a Look" tagline="Featured Work" />
        <div className="row mb-5">
          <div className="col-md-6 p-5">
            <img className="fwebWork" alt="error loading" src={Work1} />
          </div>
          <div className="col-md-6 p-5">
            <img className="fwebWork" src={Work2} alt="error loading" />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/portfolio" style={{ textDecoration: "none" }}>
            <button className="button" style={{ minWidth: "12em" }}>
              <span>View More</span>
              <span className="material-icons-outlined">arrow_forward_ios</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WorkExamples;
