import React from "react";

const ProductHeader = () => {
  return (
    <div
      className="row prodLeft"
      style={{ padding: 0, margin: 0, color: "white" }}
    >
      <div className="col-xl-6" style={{ padding: 0, margin: 0 }}>
        <div className="layer">
          <div
            className="insideLeft"
            style={{ marginLeft: 25, marginRight: 15 }}
          >
            <h1
              style={{ color: "white", fontSize: "3rem", fontFamily: "Light" }}
            >
              Take ultimate
              <br />
              control of your home
            </h1>
            <p style={{ fontFamily: "Marcellus" }}>
              We at HSL believe in bringing change in technology which can
              <br />
              truly impact the lives
            </p>
            <div>
              <button
                onClick={() => {
                  console.log("hii", document.getElementById("coverAllNeeds"));
                  document
                    .getElementById("coverAllNeeds")
                    .scrollIntoView({ behavior: "smooth" }, true);
                }}
                className="button"
                style={{ minWidth: "10em" }}
              >
                <span style={{ fontFamily: "Marcellus" }}>Explore</span>
                <span>
                  {/* <b>&gt;</b> */}
                  <i className="arrow right-arrow"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 prodRight"
        style={{ padding: 0, margin: 0 }}
      ></div>
    </div>
  );
};

export default ProductHeader;
