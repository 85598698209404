import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ItServiceHeader from "../components/ItServiceComp/ItServiceHeader";
import ServiceList from "../components/ItServiceComp/ServiceList";
import FeaturedWork from "../components/ItServiceComp/FeaturedWork";
import Contact from "../components/Contact";
import { TabTitle } from "./DynamicTitle";

const Service = () => {
  TabTitle("Service | HSL");
  return (
    <>
      <Nav textDecoService={'underline'} />
      <div>
        <ItServiceHeader />
        <ServiceList />
        <FeaturedWork />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default Service;
