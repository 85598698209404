import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Map from "../components/HompageComp/Map";
import HomepageHeader from "../components/HompageComp/HomepageHeader";
import Brands from "../components/HompageComp/Brands";
import Stack from "../components/HompageComp/Stack";
import Banner from "../components/HompageComp/Banner";
import Testimonials from "../components/HompageComp/Testimonials";
import ProductTestimonials from "../components/HompageComp/ProductTestimonials";
import HelixFamily from "../components/HompageComp/HelixFamily";
import FAQ from "../components/HompageComp/FAQ";
import Contact from "../components/Contact";
import { TabTitle } from "./DynamicTitle";

const Homepage = () => {
  TabTitle("HelixSmartLabs");
  return (
    <>
      <Nav textDecoHome={'underline'} />
      <div className="container-fluid" style={{ padding: 0 }}>
        <HomepageHeader />
        <Brands />
        <Stack />
        <Banner />
        <Testimonials />
        <Map />
        <ProductTestimonials />
        <HelixFamily />
        <FAQ />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
