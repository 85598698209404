import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import OurProducts from "./Pages/OurProducts";
import ITService from "./Pages/Service";
import ContactUs from "./Pages/ContactUs";
import AiSolutions from "./Pages/AiSolutions";
import ProductSolutions from "./Pages/ProductSolutions";
import PageNotFound from "./Pages/PageNotFound";
import ScrollToTop from "./components/ScrollToTop";
import Career from "./Pages/Career";
import Job from "./Pages/Job";
import Portfolio from "./Pages/Portfolio";
import Fitreat from "./Pages/Fitreat";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
    });
    if (window === Object) {
      window.addEventListener(
        "wheel",
        () => {
          AOS.refresh();
        },
        false
      );
    }
    AOS.refresh();
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/careers" exact component={Career} />
        <Route path="/products" exact component={OurProducts} />
        <Route path="/services" exact component={ITService} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/AI-Services" exact component={AiSolutions} />
        <Route path="/Oem-Services" exact component={ProductSolutions} />
        <Route path="/job/:title" exact component={Job} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/fitreat" exact component={Fitreat} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
