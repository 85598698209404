import React from "react";
import Airtouch from "../../assets/Air-touch-banne.jpg";
const Banner = () => {
  return (
    <div
      className="row "
      style={{ padding: 0, margin: 0, backgroundColor: "#141414" }}
    >
      <div className="col-lg-6" style={{ padding: 0, margin: 0 }}>
        <img src={Airtouch} alt="..." width="100%" />
      </div>
      <div
        className="col-lg-6 banner"
        style={{
          padding: 45,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <span className="bannerHeading" style={{fontFamily:'Marcellus'}}>Product</span>
        <h1 style={{fontFamily:'Light'}}>Air Touch</h1>
        <p style={{ textAlign: "justify",fontFamily:'Marcellus' }}>
          In contrast to traditional smart switches, Air Touch switches don't
          require any sort of physical contact from the user. Because there is
          no mechanical movement, this assures a much longer lifespan and
          reduced wear and tear. Plus, you may use this to control your home
          gadgets from the comfort of your couch.
        </p>
        <a href="/products" style={{ textDecoration: "none" }}>
          <button type="button" className="button" style={{ minWidth: "10em" }}>
            <span style={{fontFamily:'Marcellus'}}>Explore</span>
            {/* <span>&gt;</span> */}
            <i class="arrow right"></i>
          </button>
        </a>
      </div>
    </div>
  );
};

export default Banner;
