import React from "react";
import Heading from "../Heading";
import team1 from "../../assets/ComponentTMP_0-image15.png";
import team2 from "../../assets/ComponentTMP_0-image14.png";
import team3 from "../../assets/ComponentTMP_0-image17.png";
import team4 from "../../assets/ComponentTMP_0-image16.png";
const HelixFamily = () => {
  const team = [
    {
      image: team1,
      name: "Dhruv Rohatgi",
      role: "CEO & Founder",
    },
    {
      image: team2,
      name: "Dr.Suresh Kumar",
      role: "Director",
    },
    {
        image: team3,
        name: "Simran Choudhary",
        role: "Founding Member"
    },
    {
      image: team4,
      name: "Tushar Garg",
      role: "Founding Member",
    },
  ];
  return (
    <div
      className="row"
      id="team"
      style={{ textAlign: "center", padding: 0, margin: 0, color: "black" }}
    >
      <div className="col">
        <Heading heading="Grinding Behind the Scenes" tagline="Helix Family" />
        <div className="row p-5">
          {team.map((item, index) => (
            <div key={index} className="col-xl-3 col-lg-6 col-sm-6 my-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={item.image} alt="director" width="200px" />
                <h5 style={{ fontFamily: "FiraSemi" }}>{item.name}</h5>
                <span style={{ fontFamily: "Marcellus" }}>{item.role}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HelixFamily;
