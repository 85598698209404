import React from "react";

const ServiceHeader = () => {
  return (
    <div
      className="row AiLeft"
      style={{ padding: 0, margin: 0, color: "white" }}
    >
      <div className="col-xl-6" style={{ padding: 0, margin: 0 }}>
        <div className="layer">
          <div
            className="insideLeft"
            style={{ marginLeft: 25, marginRight: 15 }}
          >
            <h1>AI Solutions</h1>
            <p>
              We at HSL believe in bringing change in technology which can truly
              impact the lives
            </p>
            <div>
              <button
                onClick={() => {
                  console.log("hii", document.getElementById("coverAllNeeds"));
                  document
                    .getElementById("coverAllNeeds")
                    .scrollIntoView({ behavior: "smooth" }, true);
                }}
                className="button"
                style={{ minWidth: "10em" }}
              >
                <span>Explore</span>
                <span>
                  {/* <b>&gt;</b> */}
                  <i className="arrow right-arrow"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 AiRight" style={{ padding: 0, margin: 0 }}></div>
    </div>
  );
};

export default ServiceHeader;
