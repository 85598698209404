import React from "react";
import Diet from "../assets/fitreat/diet.svg";
import Recipe from "../assets/fitreat/recipe.svg";
import Log from "../assets/fitreat/log.svg";
function Services() {
  return (
    <div
      className="row"
      style={{ backgroundColor: "#1e1e1e", justifyContent: "space-evenly" }}
    >
      <div
        data-aos="fade-right"
        data-aos-duration="1500"
        className="col-lg-6 col-xl-3 col-md-6"
        style={{
          padding: "50px 0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 20,
          }}
        >
          <img src={Diet} alt="Diet" style={{ width: 135 }} />
        </div>
        <div>
          <p
            style={{
              fontFamily: "black-mango",
              fontSize: 28,
              margin: 0,
              padding: 0,
            }}
          >
            Calotored Diet Plans
          </p>
          <p style={{ fontSize: 18, fontWeight: "light" }}>
            Tailored by AI, drawn from thousands of diverse plans, prioritizing
            balanced nutrition, including macro and micro nutrients.
          </p>
          <button
            style={{
              background:
                "linear-gradient(90deg, rgba(83,185,81,1) 0%, rgba(26,110,96,1) 100%)",
              fontFamily: "black-mango",
              fontWeight: "bold",
              fontSize: 21,
              padding: "7px 20px",
              border: 0,
              borderRadius: 8,
              marginTop: 10,
            }}
          >
            Learn More
          </button>
        </div>
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="2000"
        className="col-lg-6 col-xl-3 col-md-6"
        style={{
          padding: "50px 0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 20,
          }}
        >
          <img src={Recipe} alt="Tasty Food Recipe" style={{ width: 135 }} />
        </div>
        <div>
          <p
            style={{
              fontFamily: "black-mango",
              fontSize: 28,
              margin: 0,
              padding: 0,
            }}
          >
            Tasty Food Recipes
          </p>
          <p style={{ fontSize: 18, fontWeight: "light" }}>
            Discover diverse, health-conscious recipes, some a breeze to
            prepare, for a well-rounded culinary experience that supports your
            well-being.
          </p>
          <button
            style={{
              background:
                "linear-gradient(90deg, rgba(83,185,81,1) 0%, rgba(26,110,96,1) 100%)",
              fontFamily: "black-mango",
              fontWeight: "bold",
              fontSize: 21,
              padding: "7px 20px",
              border: 0,
              borderRadius: 8,
              marginTop: 10,
            }}
          >
            Learn More
          </button>
        </div>
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="2500"
        className="col-lg-6 col-xl-3 col-md-6"
        style={{
          padding: "50px 0px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 20,
          }}
        >
          <img src={Log} alt="Log What You Eat" style={{ width: 135 }} />
        </div>
        <div>
          <p
            style={{
              fontFamily: "black-mango",
              fontSize: 28,
              margin: 0,
              padding: 0,
            }}
          >
            Log What You Eat
          </p>
          <p style={{ fontSize: 18, fontWeight: "light" }}>
            Track meals effortlessly with a user-friendly calorie counter, and
            gain valuable insights to better understand and achieve your body
            goals.
          </p>
          <button
            style={{
              background:
                "linear-gradient(90deg, rgba(83,185,81,1) 0%, rgba(26,110,96,1) 100%)",
              fontFamily: "black-mango",
              fontWeight: "bold",
              fontSize: 21,
              padding: "7px 20px",
              border: 0,
              borderRadius: 8,
              marginTop:10
            }}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Services;
