import React from "react";
import PortfolioHeader from "../components/portfolio/PortfolioHeader";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ic from "../assets/Picture1.png";
import Contact from "../components/Contact";
import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import img6 from "../assets/img6.png";
import img7 from "../assets/img7.png";
import img8 from "../assets/img8.png";
import img9 from "../assets/img9.png";
import img10 from "../assets/img10.png";
import img11 from "../assets/img11.png";
import img12 from "../assets/img12.png";
import img13 from "../assets/img13.png";
import img14 from "../assets/img14.png";
import img15 from "../assets/img15.png";
import img16 from "../assets/img16.png";

export default function Portfolio() {
  const portfolio = [
    {
      content: "A la Orden Discount",
      img: img1,
      des: "Content Creation & Digital Marketing",
    },
    {
      content: "Benitez Chrysler",
      img: img2,
      des: "Content Creation & Digital Marketing",
    },
    { content: "Friday Inmates", img: img3, des: "Branding" },
    { content: "Autolab", img: img4, des: "Digital Marketing" },
    { content: "Jeep Auto Parts", img: img5, des: "Branding & Design" },
    { content: "ICPR", img: img6, des: "Content Creation & Digital Marketing" },
    { content: "Coqueta", img: img7, des: "Branding & Digital Marketing" },
    { content: "America’s Leading Financial", img: img8, des: "Branding" },
    { content: "Aprobado Auto Program", img: img9, des: "Branding" },
    {
      content: "Michi",
      img: img10,
      des: "Content Creation & Digital Marketing",
    },
    {
      content: "Singular Insurance",
      img: img11,
      des: "Content Creation & Event and Training",
    },
    {
      content: "Point Guard Insurance",
      img: img12,
      des: "Content Creation & Digital Marketing",
    },
    { content: "Aprobado Auto Program", img: img13, des: "Branding" },
    {
      content: "Michi",
      img: img14,
      des: "Content Creation & Digital Marketing",
    },
    {
      content: "Singular Insurance",
      img: img15,
      des: "Content Creation & Event and Training",
    },
    {
      content: "Point Guard Insurance",
      img: img16,
      des: "Content Creation & Digital Marketing",
    },
  ];
  return (
    <>
      <Nav />
      <PortfolioHeader />
      <div className="container pt-5 pb-5 mt-5 mb-5">
        <div className="row">
          <div
            className="col-lg-4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              src={ic}
              style={{
                width: "300px",
                height: "220px",
                display: "block",
                margin: "auto",
              }}
              alt=""
            />
          </div>
          <div
            className="col-lg-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "black" }}>
              Through the years we have kept this in mind when working with
              different brands and industries, since their audience could be
              totally different as well as the way to grab their attention. This
              has created total confidence between Helix and our partners, who
              always believe in our work and continue to pick us as their
              preferred consulting agency.
            </p>
          </div>
        </div>
      </div>
      <div
        id="coverAllNeeds"
        className="pt-5 pb-5"
        style={{ backgroundColor: "#000000" }}
      >
        <div className="row container mx-auto">
          {/* <div style={{ display: "flex" }}>
            <div>

            </div>
          </div> */}
          {portfolio.map((e, i) => {
            return (
              <div className="col-lg-3 col-md-6 col-sm-12 py-3">
                <img src={e.img} style={{ width: "100%" }} alt=""/>
                {/* <div className="pt-2 pl-2" style={{ color: "white" }}>
                  {e.content}
                </div>
                <div
                  className="pl-2"
                  style={{ color: "white", fontSize: "10px", color: "#BFBFBF" }}
                >
                  {e.des}
                </div> */}
              </div>
            );
          })}
          {/* <div className="col-lg-3 col-md-6 col-sm-12 py-3">
            <img src={img1} style={{ width: "100%" }} />
          </div> */}
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
}
