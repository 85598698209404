import React from "react";
import CareerCard from "../components/CareerComp/CareerCard";
import CareerHeader from "../components/CareerComp/CareerHeader";
import Footer from "../components/Footer";
import Nav from '../components/Nav'
export default function Career() {
  return (
    <>
      <Nav />
      <CareerHeader />
      <CareerCard />
      <Footer />
    </>
  );
}
