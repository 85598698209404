import Nav from "../components/Nav";
import Footer from "../components/Footer";
import React from "react";
import OemHeader from "../components/OemPageComp/OemHeader";
import OemServices from "../components/OemPageComp/OemServices";
import FeaturedProducts from "../components/OemPageComp/FeaturedProducts";
import Contact from "../components/Contact";
import { TabTitle } from "./DynamicTitle";

const ProductSolutions = () => {
  TabTitle("OEM | HSL");

  return (
    <>
      <Nav />
      <div>
        <OemHeader />
        <OemServices />
        <FeaturedProducts />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default ProductSolutions;
