import React, { useEffect, useState } from "react";
import rightArrow from "../../assets/right-squared--v1.png";
export default function CareerCard(props) {
  const [mobile, setmobile] = useState(false);

  useEffect(() => {
    const screenSize = window.screen.width;

    if (screenSize < 800) {
      setmobile(true);
    } else {
      setmobile(false);
    }

    return () => {};
  }, []);
  const cardData = [
    {
      title: "Sales Executive",
      jobDescription:
        "With a focus of real time Collaboration smart workflows and ,intutive design  features, we make it fast and delightful",
      time: "6 Days ago",
      roleType: "Full Time Role",
    },
    {
      title: "UI and UX",
      jobDescription:
        "With a focus of real time Collaboration smart workflows and ,intutive design  features, we make it fast and delightful",
      time: "6 Days ago",
      roleType: "Full Time Role",
    },
  ];
  return (
    <div style={{ padding: "40px" }}>
      <center className="mt-5">
        <h3 style={{ color: "black", fontFamily: "Marcellus" }}>
          Our Latest Open Positions
        </h3>
        <h1
          style={{
            color: "black",
            marginBottom: "5%",
            // marginLeft: "9%",
            // marginTop: "1.5%",
            fontSize: `${mobile ? "2rem" : "4rem"}`,
            fontFamily: "Light",
          }}
        >
          Find Your Best Fit
        </h1>
      </center>

      <div className="container">
        <div className="row">
          {cardData.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card mb-2 career-card">
                <div
                  className="card-body"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <h1
                    className="card-title"
                    style={{
                      color: "black",
                      fontFamily: "Light",
                      // padding: "4%",
                    }}
                  >
                    <center>{item.title}</center>
                  </h1>
                  <h6
                    className="card-subtitle  mx-2 pb-2 text-muted"
                    style={{ padding: "4%", justifyContent: "center" }}
                  >
                    {item.jobDescription}
                  </h6>
                  <h6
                    className="mx-2"
                    style={{ color: "black", padding: "4%" }}
                  >
                    {item.time}
                  </h6>

                  <div
                    style={{
                      color: "black",
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginBottom: "0px",
                      paddingBottom: "0px",
                      paddingLeft: "6px",
                      marginTop: "5px",
                      backgroundColor: "#34b6dd",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",

                      // justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "4px",
                        // paddingTop: "4px",
                        fontFamily: "Light",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",

                          paddingLeft: "4px",
                          // paddingTop: "4px",
                          fontFamily: "Light",
                          lineHeight: "20px",
                        }}
                      >
                        {item.roleType}{" "}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "4px",
                        // paddingTop: "4px",
                        fontFamily: "Light",
                      }}
                    >
                      <span>
                        <a href={`/job/${item.title.trim(" ")}`}>
                          <img src={rightArrow} style={{ width: "40px" }} alt="" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
