import React from 'react'

export default function PortfolioHeader() {
    return (
        <div className="row" style={{ padding: 0, margin: 0 }}>
            <div className="col-xl-6 leftHead" style={{ padding: 0, margin: 0 }}>
                <div className="layer">
                    <div
                        className="insideLeft"
                        style={{ marginLeft: 25, marginRight: 15 }}
                    >
                        <h1 style={{ color: "white", fontSize: "3rem", fontFamily: 'Light' }}>
                            At Helix we build
                            <br />
                            Connections between <br />
                            our clients
                        </h1>
                        
                            <p style={{ fontSize: "1rem", fontFamily: 'Marcellus',padding:'0px' }}>
                                And its consumers by studying their behavior and <br />
                                what influences them as a consumer.
                                
                            </p>
                        
                        <div>
                            <button
                                onClick={() => {
                                    console.log("hii", document.getElementById("coverAllNeeds"));
                                    document
                                        .getElementById("coverAllNeeds")
                                        .scrollIntoView({ behavior: "smooth" }, true);
                                }}
                                className="button"
                                style={{ minWidth: "10em" }}
                            >
                                <span style={{ fontFamily: 'Marcellus' }}>Explore</span>
                                <span>
                                    <b>&gt;</b>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 back" style={{ padding: 0, margin: 0 }}></div>
        </div>
    )
}
