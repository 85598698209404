import React from "react";

function Insights() {
  return (
    <svg
      height="80"
      className="whiteSvg"
      id="line-graph"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76.138 74.01"
    >
      <path
        id="Path_1280"
        data-name="Path 1280"
        d="M67.789,44.027H56.22a1.118,1.118,0,0,0,0,2.235H67.789A6.12,6.12,0,0,1,73.9,52.375V87.294H61.036a1.118,1.118,0,1,0,0,2.235H73.8a6.122,6.122,0,0,1-6.009,5h-29.2a1.118,1.118,0,1,0,0,2.235h6.4v4.4a1.118,1.118,0,0,0,.272.73l2.629,3.045H28.4l2.629-3.045a1.118,1.118,0,0,0,.272-.73v-4.4H33.83a1.118,1.118,0,1,0,0-2.235H8.348a6.122,6.122,0,0,1-6.009-5H56.273a1.118,1.118,0,1,0,0-2.235H2.235V52.375a6.12,6.12,0,0,1,6.113-6.113H20.066a1.118,1.118,0,0,0,0-2.235H8.348A8.358,8.358,0,0,0,0,52.375V88.411A8.358,8.358,0,0,0,8.348,96.76H29.063v3.988l-3.618,4.191h-1.49a3.238,3.238,0,0,0-3.234,3.234v1.133a3.238,3.238,0,0,0,3.234,3.234H52.182a3.238,3.238,0,0,0,3.234-3.234v-1.133a3.238,3.238,0,0,0-3.234-3.234H50.842l-3.618-4.191V96.76H67.789a8.358,8.358,0,0,0,8.348-8.348V52.375A8.358,8.358,0,0,0,67.789,44.027ZM53.181,108.173v1.133a1,1,0,0,1-1,1H23.955a1,1,0,0,1-1-1v-1.133a1,1,0,0,1,1-1H52.182A1,1,0,0,1,53.181,108.173Z"
        transform="translate(0 -38.53)"
      />
      <path
        id="Path_1281"
        data-name="Path 1281"
        d="M79.462,24.806a1.118,1.118,0,0,0,1.21-1.017,15.751,15.751,0,0,1,5.037-10.236q.511-.471,1.058-.892l8.715,13.134a1.118,1.118,0,0,0,.931.5h15.762a15.833,15.833,0,0,1-9.162,13.239l-6.408-6.059a4.141,4.141,0,1,0-7.486-.33l-1,.791a1.118,1.118,0,1,0,1.387,1.753l1-.793a4.131,4.131,0,0,0,4.562.2l5.6,5.3A15.809,15.809,0,0,1,80.908,28.24a1.118,1.118,0,1,0-2.193.431,17.933,17.933,0,0,0,4.69,8.994l-8.356,6.608a4.135,4.135,0,1,0,1.388,1.752l8.636-6.83a18.016,18.016,0,0,0,17.452,2.953l3.751,3.547a4.142,4.142,0,1,0,7.341.022l8.142-7.606a4.149,4.149,0,1,0-1.526-1.634l-8.142,7.606a4.133,4.133,0,0,0-4.28-.013l-3.066-2.9A18.036,18.036,0,1,0,78.445,23.6a1.117,1.117,0,0,0,1.017,1.21Zm11.573,6.742a1.907,1.907,0,1,1,1.907,1.906A1.909,1.909,0,0,1,91.035,31.548ZM72.616,49.528a1.907,1.907,0,1,1,1.907-1.907A1.909,1.909,0,0,1,72.616,49.528ZM123.91,32.665A1.906,1.906,0,1,1,122,34.572,1.909,1.909,0,0,1,123.91,32.665ZM111.325,46.314l.006.008,0,0a1.906,1.906,0,1,1-2.78-.011h0l0,0a1.9,1.9,0,0,1,2.765,0Zm.85-22.255H97.013L88.628,11.423a15.805,15.805,0,0,1,23.548,12.636Z"
        transform="translate(-58.27 -7.139)"
      />
    </svg>
  );
}

export default Insights;
