import React from "react";
import { Redirect } from "react-router-dom";

const Card = (props) => {
  console.log(props.data);
  const redirect = false;
  if (redirect) {
    return <Redirect to={props.link} />;
  }
  return (
    <div
      className="col-xl-4"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          padding: 50,
          paddingTop: 0,
          marginTop: "20px",
          paddingBottom: 0,
          // marginTop: -150,
          // display: "flex",
          alignItems: "center",
          width: "300px",
          height: "200px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <img
          src={props.image}
          alt="Error Loading File"
          width="100%"
          style={{ display: "block", margin: "auto" }}
        />
      </div>
      <div className="serviceCard">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 80,
          }}
        >
          <h3 style={{ fontFamily: "Light" }}>{props.heading}</h3>
        </div>
        <div
          style={{
            padding: 30,
            paddingTop: 0,
            textAlign: "left",
            fontFamily: "Marcellus",
          }}
        >
          {props.data.length > 55 ? props.data.slice(0, 125) : props.data}..
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: -15,
          }}
        >
          <a href={props.link} style={{ textDecoration: "none" }}>
            <button
              className="button"
              style={{ padding: 10, fontFamily: "Marcellus" }}
              // onClick={() => setRedirect(true)}
            >
              Explore
              <span className="material-icons-outlined">arrow_forward_ios</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
