import React from 'react';
import c1 from '../../assets/airTouch.png';
import c2 from '../../assets/wifiEnabled.png';
import c3 from '../../assets/playStore.png';
import c4 from '../../assets/waterResistance.png';
const ConnectivityBar = () => {
    const connOptions = [
        { image: c1, heading: "Air Touch" },
        { image: c2, heading: "Wi-Fi Enabled" },
        { image: c3, heading: "App Controlled" },
        { image: c4, heading: "Waterproof" }
    ]
    return (
        <div className="row" style={{ padding: 10, margin: 0,backgroundColor: "rgb(31,31,31)", color: "rgb(168,168,168)" }}>
            {connOptions.map((item, index) => (
                <div key={index} className="col-md-3 col-sm-6 my-3">
                    <div style={{ display: "flex", flexDirection: "column", alignItems:"center" }}>
                        <div><img src={item.image} alt="unable to load content" height="100px" /></div>
                        <div>{item.heading}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ConnectivityBar;