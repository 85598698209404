import React from "react";
import Heading from "../Heading";
import pro1 from "../../assets/prod1.png";
import pro2 from "../../assets/prod2.png";
const FeaturedProducts = () => {
  return (
    <div
      className="row p-3"
      style={{
        padding: 0,
        margin: 0,
        textAlign: "center",
        color: "white",
        backgroundColor: "rgb(19,19,19)",
      }}
    >
      <div className="col">
        <Heading heading="Have a Look" tagline="Featured Work" />
        <div className="row">
          <div className="col-md-6 my-3">
            <img
              alt="error loading"
              src={pro2}
              style={{ transform: "rotate(21.5deg)", maxWidth: "100%" }}
            />
          </div>
          <div className="col-md-6 my-3">
            <img alt="eror loading" src={pro1} style={{ maxWidth: "100%" }} />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/portfolio" style={{ textDecoration: "none" }}>
            <button className="button" style={{ minWidth: "12em" }}>
              <span>View More</span>
              <span className="material-icons-outlined">arrow_forward_ios</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
