import React from "react";
import Heading from "../Heading";
import web from "../../assets/fweb.png";
import app from "../../assets/fapp.png";
const FeaturedWork = () => {
  return (
    <div
      className="row p-3"
      style={{
        padding: 0,
        margin: 0,
        textAlign: "center",
        color: "white",
        backgroundColor: "rgb(19,19,19)",
      }}
    >
      <div className="col">
        <Heading heading="Have a Look" tagline="Featured Work" />
        <div className="row">
          <div className="col-md-4 my-3">
            <img className="fwebWork" alt="error loading" src={web} />
          </div>
          <div className="col-md-4 my-3">
            <img className="fappWork" alt="eror loading" src={app} />
          </div>
          <div className="col-md-4 my-3">
            <img className="fwebWork" src={web} alt="error loading" />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/portfolio" style={{ textDecoration: "none" }}>
            <button className="button" style={{ minWidth: "12em" }}>
              <span>View More</span>
              <span className="material-icons-outlined">arrow_forward_ios</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeaturedWork;
