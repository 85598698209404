import React from "react";
import PlayStoreBig from "../assets/fitreat/playstore-big.svg";
import AppStoreBig from "../assets/fitreat/appstore-big.svg";
import AppStoreIcon from "../assets/fitreat/app.svg";
import PlayStoreIcon from "../assets/fitreat/play.svg";
import Goog from "../assets/fitreat/goog.svg";
import Better from "../assets/fitreat/tbi.svg";
import Star from "../assets/fitreat/fit-star.svg";

function Highlights() {
  return (
    <div
      className="row remove-padding-50"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        backgroundColor: "#1e1e1e",
        padding: 50,
      }}
    >
      <div
        className="col-md-3 remove-margin-bottom"
        style={{
          backgroundColor: "#121212",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 10,
        }}
      >
        <p
          style={{
            padding: 0,
            fontFamily: "black-mang0",
            fontSize: 48,
            fontWeight: "bold",
          }}
        >
          Free to use
        </p>
        <img src={PlayStoreBig} alt="" />
        <img src={AppStoreBig} style={{ marginTop: 20 }} alt="" />
      </div>
      <div
        className="col-md-3 remove-margin-bottom"
        style={{
          backgroundColor: "#121212",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 10,
        }}
      >
        <p
          style={{
            padding: 0,
            fontFamily: "black-mang0",
            fontSize: 48,
            fontWeight: "bold",
          }}
        >
          <span style={{ display: "inline-block" }}>5&nbsp;</span>
          <img src={Star} alt="" style={{ display: "inline-block", marginTop:-18 }}/> Rating
        </p>
        <div
          style={{
            padding: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <img src={AppStoreIcon} alt="" />
          <img src={PlayStoreIcon} alt="" />
          <img src={Goog} alt="" />
        </div>
      </div>
      <div
        className="col-md-3 remove-margin-bottom"
        style={{
          backgroundColor: "#121212",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 10,
        }}
      >
        <p
          style={{
            padding: 0,
            fontFamily: "black-mang0",
            fontSize: 48,
            fontWeight: "bold",
          }}
        >
          Featured In
        </p>
        <img src={Better} alt="" />
      </div>
    </div>
  );
}

export default Highlights;
