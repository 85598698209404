import React from 'react';

const ContactHeader = () => {
    return (
        <div className="row ContactLeft" style={{ padding: 0, margin: 0, color: "white" }}>
            <div className="col-xl-6" style={{ padding: 0, margin: 0 }}>
                <div className="layer">
                    <div className="insideLeft" style={{ marginLeft: 25, marginRight: 15 }}>
                        <h1 style={{fontFamily:'Light'}}>Get in Touch</h1>
                        <p style={{fontFamily:'Marcellus'}}>We at HSL believe in bringing change in technology which can truly impact the lives</p>
                        <div >
                            <button className="button" style={{ minWidth: "10em",fontFamily:'Marcellus' }}><span>Explore</span><span><i className='arrow right-arrow'></i></span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 ContactRight" style={{ padding: 0, margin: 0 }}></div>
        </div>
    );
};

export default ContactHeader;