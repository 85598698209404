import React, { useState } from "react";
import lift from "../../assets/lift_w.png";
import s1 from "../../assets/s3.png";
import s2 from "../../assets/s2_w.jpg";

const PlayList = () => {
  const [active, updateActive] = useState(0);
  const ListItems = [lift, s1, s2];
  const prev = () => {
    if (active === 0) {
      updateActive(ListItems.length - 1);
    } else {
      updateActive(active - 1);
    }
  };
  const next = () => {
    if (active === ListItems.length - 1) {
      updateActive(0);
    } else {
      updateActive(active + 1);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "rgb(31,31,31)",
        color: "white",
        margin: "10% 25% 10% 25%",
        height: "50%",
        minHeight: "200px",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${
            active === 0
              ? ListItems[ListItems.length - 1]
              : ListItems[active - 1]
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% auto",
          height: "100%",
          position: "relative",
          top: "-50%",
          left: "-30%",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${ListItems[active]})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100% auto",
            height: "100%",
            position: "relative",
            right: "-60%",
            bottom: "-75%",
          }}
        ></div>
      </div>
      <div style={{ display: "flex", position: "relative", top: "-25%" }}>
        <button type="button" className="button mx-2" onClick={() => prev()}>
          <span class="material-icons-outlined">arrow_back_ios</span>
        </button>
        <button type="button" className="button mx-2" onClick={() => next()}>
          <span class="material-icons-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </div>
  );
};

export default PlayList;
