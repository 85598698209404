import React from "react";
import microsoft from "../../assets/nasa_white.png";
import ibm from "../../assets/nhs white.png";
import infosys from "../../assets/ba_white.png";
import intel from "../../assets/DRDO_white.png";
const Brands = () => {
  return (
    <div
      className="row"
      style={{ padding: 0, margin: 0, backgroundColor: "#1F1F1F" }}
    >
      <div className="col">
        <div className="row mt-2">
          <div className="col">
            <div style={{ padding: 40 }}>
              <center>
                <h3>Loved by Industry Leaders.</h3>
              </center>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-3 loveImage-container">
            <img src={microsoft} alt="Microsoft" className="loveImage" />
          </div>
          <div className="col-md-3 loveImage-container">
            <img src={ibm} alt="Intel" className="loveImage" />
          </div>
          <div className="col-md-3 loveImage-container">
            <img src={intel} alt="IBM" className="loveImage" />
          </div>
          <div className="col-md-3 loveImage-container">
            <img src={infosys} alt="Infosys" className="loveImage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
