import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { TabTitle } from "./DynamicTitle";

const PageNotFound = () => {
  TabTitle("404 | Page Not Found");
  return (
    <>
      <Nav />
      <div
        className="row"
        style={{
          textAlign: "center",
          padding: "25%",
          height: "100vh",
          margin: 0,
          backgroundColor: "black",
        }}
      >
        <h1>Page Not Found!</h1>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
