import React, { useState } from "react";

const QuestionDropdown = (props) => {
  const [show, updateShow] = useState(false);
  return (
    <div>
      <div
        className="p-2 my-2"
        style={{
          display: "flex",
          backgroundColor: "#1f1f1f",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left",
          width: "100%",
        }}
      >
        <span>{props.ques}</span>
        {show ? (
          <i
            style={{ fontSize: "2em" }}
            onClick={() => updateShow(false)}
            class="material-icons-outlined"
          >
            expand_less
          </i>
        ) : (
          <i
            style={{ fontSize: "2em" }}
            onClick={() => updateShow(true)}
            class="material-icons-outlined"
          >
            expand_more
          </i>
        )}
      </div>
      {show ? (
        <div
          className="p-3 my-2"
          style={{
            display: "flex",
            backgroundColor: "#1f1f1f",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: ".5em",
            whiteSpace: "pre-line",
            fontSize: "0.9rem",
            textAlign: "justify",
          }}
        >
          {props.ans}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default QuestionDropdown;
