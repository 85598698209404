import React from "react";

const HomepageHeader = () => {
  return (
    <div className="row" style={{ padding: 0, margin: 0 }}>
      <div className="col-xl-6 leftHead" style={{ padding: 0, margin: 0 }}>
        <div className="layer">
          <div
            className="insideLeft"
            style={{ marginLeft: 25, marginRight: 15 }}
          >
            <h1 style={{ color: "white", fontSize: "3rem" ,fontFamily:'Light'}}>
              Defining
              <br />
              tomorrow, today.
            </h1>
            <div>
              <p style={{ fontSize: "1.4rem",fontFamily:'Marcellus' }}>
                We at HSL believe in bringing change in technology that <br/>
                can truly impact the lives of people and make their life easier.
              </p>
            </div>
            <div>
              <button
                onClick={() => {
                  console.log("hii", document.getElementById("coverAllNeeds"));
                  document
                    .getElementById("coverAllNeeds")
                    .scrollIntoView({ behavior: "smooth" }, true);
                }}
                className="button"
                style={{ minWidth: "10em" }}
              >
                <span style={{fontFamily:'Marcellus'}}>Explore</span>
                <span>
                <i class="arrow right-arrow"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 back" style={{ padding: 0, margin: 0 }}></div>
    </div>
  );
};

export default HomepageHeader;
