import React from "react";
import PlayList from "./PlayList";
const About = () => {
  return (
    <div
      className="row"
      style={{ padding: 0, margin: 0, backgroundColor: "#131313" }}
    >
      <div className="col-md-8 mt-5">
        <PlayList />
      </div>
      <div className="col-md-4 HSLBackground">
        <div
          style={{
            backgroundColor: "rgba(19, 19, 19, 0.7)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // paddingRight: 80,
          }}
        >
          <div>
            We at HSL believe in bringing change in technology which can truly
            impact the lives of people and make their life easier. To ensure
            that, we have inline more than 10 products which can ease your daily
            life.
          </div>
          <br />
          <div>
            With variants ranging from smart home appliances to security
            devices, get anything and everything that brings life to your home.
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
