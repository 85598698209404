import React from "react";
import Carousel from "react-elastic-carousel";
import comma from "../../assets/comma.png";
import comma2 from "../../assets/comma-2.png";
// import user1 from "../../assets/Chad-Circle-Headshot-01.png";
// import user2 from "../../assets/Eleanor-Galtie-black-and-white-circle-headshot.png";
const Testimonials = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 1200, itemsToShow: 2 },
  ];
  const items = [
    {
      data: `When I first started my business, the one thing that kept me up at night was how I would communicate with clients if I did not have a website that was both dynamic and responsive. However, after I entrusted HelixSmartLabs with the responsibility of developing my website, I was able to relax knowing that my company was in good hands. They created the website from scratch and are doing excellent job of maintaining it.`,
      user: "Rhythm Mehra",
      image:
        "https://lh3.googleusercontent.com/a-/AD5-WClg3ppMFhyJwMnJOeY_F7X1NDNZNzDpICB310mb=s40-c-c0x00000000-cc-rp-mo-br100",
      company: "carbonix",
    },
    {
      data: `The team at HelixSmartLabs have a great level of expertise and experience; in addition, the work they did for my organisation is of a high quality. When it comes to the designing and developing of websites, they are the most skilled company around. They have developed the website with a level of quality that is beyond our ability to comprehend.`,
      user: "Nikita Goyal",
      image:
        "https://lh3.googleusercontent.com/a/AEdFTp5x-wyJoDdvP-A0RqQ798CGJ_9hGyjTd8NqVzX0=s40-c-c0x00000000-cc-rp-mo-br100",
      company: "abb",
    },
    {
      data: `Easily accessible in Lakshmi Nagar, New Delhi. Close to the V3S Mall. These folks are well-trained professionals and provide services that are genuine as well as cost-effective and are available with both online and offline technical support.`,
      user: "Harshit Sadeja",
      image:
        "https://lh3.googleusercontent.com/a/AEdFTp5zE8JmOo23XDwDkYORDDGXhFq5XuYDCXEQq9hP=s40-c-c0x00000000-cc-rp-mo-br100",
      company: "carbonix",
    },
  ];
  return (
    <div
      className="row"
      style={{
        padding: 0,
        margin: 0,
        backgroundColor: "#141414",
        paddingTop: "5em",
      }}
    >
      <Carousel className="col" breakPoints={breakPoints} itemsToScroll={1}>
        {items.map((item, index) => (
          <div className="testimonial" key={index}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={comma} alt="" width="200px" />
            </div>
            <p style={{ padding: "1em", textAlign: "justify",fontFamily:'Marcellus' }}>
              {item.data.length > 100
                ? `${item.data.slice(0, 200)}...`
                : item.data}
            </p>
            <div className="row" style={{ padding: 0, margin: 0 }}>
              <div
                className="col-md-6"
                style={{ padding: 0, margin: 0, paddingLeft: "3%" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginLeft: 10,
                    marginBottom: 10,
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      style={{ borderRadius: "50%" }}
                      src={item.image}
                      alt=""
                      width="50px"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 10,
                    }}
                  >
                    <span style={{ display: "block", fontWeight: 600,fontFamily:'Light' }}>
                      {item.user}
                    </span>
                    {/* <span style={{ color: "rgb(187,187,187)" }}>
                      {item.company}
                    </span> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ padding: 0, margin: 0 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <img src={comma2} alt="" width="200px" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
