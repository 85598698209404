import React from "react";
import phone from "../../assets/phone.png";
import Control from "./componentsSVG/Control";
import Schedule from "./componentsSVG/Schedule";
import Insights from "./componentsSVG/Insights";
import Track from "./componentsSVG/Track";
import "./floating.css";
const FloatingPhone = () => {
  return (
    <div className="row p-5 d1">
      <div className="col-md-4 blackOnSmall left">
        <div className="my-3 pill1">
          <div className="pillAlign">
            <Control />
          </div>
          <div style={{ marginTop: "1rem" }}>Control from Anywhere</div>
        </div>
        <div className="my-3 pill2">
          <div className="pillAlign">
            <Schedule />
          </div>
          <div style={{ marginTop: "1rem" }}>Schedule Task</div>
        </div>
      </div>

      <div className="col-md-4 cen">
        <img className="phone" alt="track" src={phone} />
      </div>

      <div className="col-md-4 right">
        <div className="my-3 whiteOnSmall pill3">
          <div className="pillAlign">
            <Insights />
          </div>
          <div style={{ marginTop: "1rem" }}>Deep Insights</div>
        </div>
        <div className="my-3 whiteOnSmall pill4">
          <div className="pillAlign">
            <Track />
          </div>
          <div style={{ marginTop: "1rem" }}>Track Appliances</div>
        </div>
      </div>
    </div>
  );
};

export default FloatingPhone;
