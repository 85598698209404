import React from "react";
import Heading from "../Heading";
import ProdTestCard from "./ProdTestCard";
import card2 from "../../assets/ComponentTMP_0-image11.jpg";
import card1 from "../../assets/ComponentTMP_0-image10.jpg";
import card3 from "../../assets/ComponentTMP_0-image12.jpg";
import card4 from "../../assets/ComponentTMP_0-image13.jpg";
const ProductTestimonials = () => {
  return (
    <div
      className="row"
      style={{ textAlign: "center", padding: 0, margin: 0, color: "black" }}
    >
      <div className="col">
        <Heading
          heading="Hear it from the Users"
          tagline="Product Testimonials"
        />
        <div className="row my-3" style={{ padding: 0 }}>
          <div className="col-md-6 mt-2">
            <ProdTestCard
              cover={card1}
              heading="Airtouch"
              desc="Elegance at its best. Operate gadgets from around the world."
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 mt-2">
                <ProdTestCard
                  cover={card2}
                  heading="Elevators"
                  desc="Hygiene at its best. Air Touch Switches ensures zero transmission "
                />
              </div>
              <div className="col-md-6 mt-2">
                <ProdTestCard
                  cover={card3}
                  heading="Modular"
                  desc="Replace your existing switches with ease."
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <ProdTestCard
                  cover={card4}
                  heading="Elegant For Your Beautiful Home"
                  desc="Suits your luxury."
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <a style={{textDecoration:'none',color:'white'}} href="/products">
            <button className="button" style={{ minWidth: "12em" }}>
              <span style={{fontFamily:'Marcellus'}}>Read all</span>
              <span>
                <i className="arrow right"></i>
              </span>
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTestimonials;
