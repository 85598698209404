import React from 'react';
import people from '../../assets/people.jpg';
import linkedin from '../../assets/linkedin.png';
import facebook from '../../assets/fb.png';
import instagram from '../../assets/insta.png';
const SocialBlock = () => {
    const handleEmail = (e) => {
        e.preventDefault()
    }
    return (
        <div className="row" style={{ margin: "0 10% 10% 10%" }}>
            <div className="col-md-6 my-5">
                <div>
                    <img src={people} alt="people" width="100%" />
                </div>
                <div className="p-5" style={{ backgroundColor: "#34b6dd" }}>
                    <p>
                        Interested in getting updates on our new products,
                        services, discounts etc.
                    </p>
                    <h4>Subscribe to our Newsletter</h4>
                    <form style={{ display: "flex", justifyContent: "space-between" }} onSubmit={(e) => handleEmail(e)}>
                        <input style={{ width: "80%" }} placeholder="Email Address" type="email" className="form-control" />
                        <button className="buttonDark" type="submit">
                            <i className="material-icons-outlined">arrow_forward_ios</i>
                        </button>
                    </form>
                </div>
            </div>
            <div className="col-md-6 my-5" style={{ backgroundColor: "rgb(31,31,31)" }}>
                <div className="p-5">
                    <h1 className="display-1">Follow our Journey!</h1>
                    <p>Through our Social media.</p>
                    <div className="row my-5 mr-5">
                        <div className="col"><img src={linkedin} alt="linkedin" width="100%" /></div>
                        <div className="col"><img src={facebook} alt="fb" width="100%" /></div>
                        <div className="col"><img src={instagram} alt="instagram" width="100%" /></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SocialBlock;