import React from "react";

function Track() {
  return (
    <svg
      height="80"
      id="outline"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.918 71.53"
    >
      <path
        id="Path_1283"
        data-name="Path 1283"
        d="M151.639,16H115.278A3.288,3.288,0,0,0,112,19.278V41.184a1.192,1.192,0,0,0,2.384,0V23.153h38.149v54.84H114.384V48.174a1.192,1.192,0,0,0-2.384,0V84.251a3.288,3.288,0,0,0,3.278,3.278h36.361a3.288,3.288,0,0,0,3.278-3.278V19.278A3.288,3.288,0,0,0,151.639,16Zm.894,68.251a.9.9,0,0,1-.894.894H115.278a.9.9,0,0,1-.894-.894V80.377h38.149Zm0-63.483H114.384v-1.49a.9.9,0,0,1,.894-.894h36.361a.9.9,0,0,1,.894.894Z"
        transform="translate(-112 -16)"
        fill="#fff"
      />
      <path
        id="Path_1284"
        data-name="Path 1284"
        d="M217.192,458.384h9.537a1.192,1.192,0,1,0,0-2.384h-9.537a1.192,1.192,0,0,0,0,2.384Z"
        transform="translate(-200.502 -390.431)"
        fill="#fff"
      />
      <path
        id="Path_1285"
        data-name="Path 1285"
        d="M209.882,264h-16.69A1.2,1.2,0,0,0,192,265.192v16.69a1.2,1.2,0,0,0,1.192,1.192h16.69a1.2,1.2,0,0,0,1.192-1.192v-16.69A1.2,1.2,0,0,0,209.882,264Zm-1.192,16.69H194.384v-9.537H208.69Zm0-11.922H194.384v-2.384H208.69Z"
        transform="translate(-180.078 -227.043)"
        fill="#fff"
      />
      <path
        id="Path_1286"
        data-name="Path 1286"
        d="M203.595,81.064a10.038,10.038,0,0,0-8.9,0,9.876,9.876,0,0,0-5.5,8.9v.1a9.922,9.922,0,0,0,2.4,6.467l6.646,7.749a1.187,1.187,0,0,0,1.684.134l.134-.134,6.646-7.749a9.923,9.923,0,0,0,2.4-6.467v-.1A9.933,9.933,0,0,0,203.595,81.064Zm3.115,9a7.542,7.542,0,0,1-1.818,4.918l-5.737,6.706L193.417,95A7.542,7.542,0,0,1,191.6,90.08v-.1a7.555,7.555,0,1,1,15.111,0Z"
        transform="translate(-177.695 -70.484)"
        fill="#fff"
      />
      <path
        id="Path_1287"
        data-name="Path 1287"
        d="M232.513,122a4.113,4.113,0,1,0,4.113,4.113A4.112,4.112,0,0,0,232.513,122Zm0,5.842a1.729,1.729,0,1,1,1.729-1.729A1.73,1.73,0,0,1,232.513,127.842Z"
        transform="translate(-211.054 -106.204)"
        fill="#fff"
      />
      <path
        id="Path_1288"
        data-name="Path 1288"
        d="M233.192,330.384h4.769a1.192,1.192,0,0,0,0-2.384h-4.769a1.192,1.192,0,0,0,0,2.384Z"
        transform="translate(-214.118 -281.506)"
        fill="#fff"
      />
      <path
        id="Path_1289"
        data-name="Path 1289"
        d="M153.192,306.384h1.192a1.192,1.192,0,0,0,0-2.384h-1.192a1.192,1.192,0,1,0,0,2.384Z"
        transform="translate(-146.039 -261.082)"
        fill="#fff"
      />
      <path
        id="Path_1290"
        data-name="Path 1290"
        d="M145.192,338.384h2.384a1.192,1.192,0,0,0,0-2.384h-2.384a1.192,1.192,0,1,0,0,2.384Z"
        transform="translate(-139.231 -288.313)"
        fill="#fff"
      />
      <path
        id="Path_1291"
        data-name="Path 1291"
        d="M154.384,368h-1.192a1.192,1.192,0,1,0,0,2.384h1.192a1.192,1.192,0,1,0,0-2.384Z"
        transform="translate(-146.039 -315.545)"
        fill="#fff"
      />
    </svg>
  );
}

export default Track;
