import React from "react";
import Heading from "../Heading";
import webDev from "../../assets/webDev.png";
import appDev from "../../assets/appDev.png";
import softDev from "../../assets/softDev.png";
const ServiceList = () => {
  const data = [
    {
      image: webDev,
      heading: "Website Development",
      text: "We'll elevate your online presence with our cutting-edge web development solutions, tailored to your unique needs and goals.",
    },
    {
      image: appDev,
      heading: "Application Development",
      text: "Our experienced team will help you harness the power of AI to improve your operations, increase efficiency and drive growth.",
    },
    {
      image: softDev,
      heading: "Product Development",
      text: "We'll help you bring your innovative ideas to life with our product development services, tailored to your unique needs.",
    },
  ];
  return (
    <div  id="coverAllNeeds" className="row p-2" style={{ textAlign: "center", color: "black",width:'100%' }}>
      <Heading
        heading="Tackle all your Needs"
        tagline="Stack of Services you can't Resist"
      />
      <div
        className="row mt-5"
       
        style={{ padding: 0, margin: 0 }}
      >
        {data.map((item, index) => (
          <div key={index} className="col-md-4">
            <div className="variantCard" style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
              <img
                src={item.image}
                alt="can't load"
                style={{ maxWidth: "100%", height:300, alignSelf:"center" }}
              />
              <div className="variantContent">
                <h4 className="pt-3" style={{ fontFamily: "Light" }}>
                  {item.heading}
                </h4>
                <p
                  className="px-4"
                  style={{ textAlign: "justify", fontFamily: "Marcellus" }}
                >
                  {item.text}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="/contact-us"
                >
                  <button
                    type="button"
                    className="button"
                    style={{ minWidth: "10em", marginBottom: -15 }}
                  >
                    <span style={{ fontFamily: "Marcellus" }}>Get Quote</span>
                    <span className="material-icons-outlined">
                      arrow_forward_ios
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceList;
