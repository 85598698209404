import React from "react";
// import Left from "../assets/fitreat/testimonial-left.svg";
// import Middle from "../assets/fitreat/testimonial-middle.svg";
// import Right from "../assets/fitreat/testimonial-right.svg";
// import Quote from "../assets/fitreat/testimonial-quote.svg";
import Test from "../assets/fitreat/test.png";
function Testimonials() {
  return (
    <div className="row" style={{ backgroundColor: "#121212" }}>
      <center>
        <p
          style={{
            fontSize: 90,
            color: "#53b951",
            fontFamily: "black-mango",
            paddingRight: 0,
          }}
          className="testimonial-txt"
        >
          Testimonials
        </p>
      </center>
      <div className="col-md-12" style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
        <img src={Test} alt="" />
      </div>
    </div>
  );
}

export default Testimonials;
