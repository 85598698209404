import React from "react";
import Heading from "../Heading";
import Card from "./Card";
import first from "../../assets/IMG_5309.JPG";
import second from "../../assets/IMG_5308.JPG";
import third from "../../assets/IMG_5307.JPG";
import manufacturing from "../../assets/IMG_5346.JPG";
import Client from "../../assets/IMG_5345.JPG";
import ITprducts from "../../assets/IMG_5344.JPG";
import Partners from "../../assets/IMG_5347.JPG";
// import CardContainer from "../CardContainer";
const Stack = () => {
  const services = [
    {
      image: first,
      heading: "IT Solutions",
      data: `We'll elevate your online presence with our cutting-edge web development solutions, tailored to your unique needs and goals.`,
      link: "/services",
    },
    {
      image: second,
      heading: "AI Solutions",
      data: `Our experienced team will help you harness the power of AI to improve your operations, increase efficiency and drive growth.`,
      link: "/AI-Services",
    },
    {
      image: third,
      heading: "Product Development",
      data: `We'll help you bring your innovative ideas to life with our product development services, tailored to your unique needs and goals.`,
      link: "/Oem-Services",
    },
  ];
  const experiences = [
    { image: Client, count: 50, desc: "Clients" },
    { image: manufacturing, count: 100, desc: "Products" },
    { image: Partners, count: 10, desc: "Prototypes" },
    { image: ITprducts, count: 5, desc: "Locations" },
  ];
  return (
    <div
      className="row container mx-auto"
      id="coverAllNeeds"
      style={{ textAlign: "center", padding: 0, margin: 0, color: "black" }}
    >
      <div className="col">
        <Heading
          heading="Rich Stack of Services"
          tagline="Cover all your needs"
        />
        <div
          className="row mb-2"
          style={{
            paddingTop: "2%",
            paddingBottom: "2%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {services.map((item, index) => (
            <Card
              key={index}
              image={item.image}
              heading={item.heading}
              data={item.data}
              link={item.link}
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "4%",
          }}
        >
          <a style={{textDecoration:'none'}} href='/contact-us'>
          <button className="button" style={{ minWidth: "14em" }}>
            <span style={{fontFamily:'Marcellus'}}>Get an Instant Quote</span>
            <span>
              {/* <b>&gt;</b> */}
              <p><i class="arrow right-arrow"></i></p>
            </span>
          </button>
          </a>
        </div>
        <Heading heading="Expanding day by day" tagline="Our Experience" />
        <div className="row" style={{ padding: "5% 8%" }}>
          {experiences.map((item, index) => (
            <div key={index} className="col-xl-3 col-sm-6 p-5">
              <div style={{ display: "flex" }}>
                <div>
                  <img src={item.image} alt="..." width="100em" />
                </div>
                <div style={{ textAlign: "left", marginLeft: "1.5em" }}>
                  <span
                    style={{
                      fontSize: "2em",
                      display: "flex",
                      alignContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.count}</span>
                    <i
                      style={{ color: "#34b6dd" }}
                      class="material-icons-outlined"
                    >
                      add
                    </i>
                  </span>
                  <span
                    style={{
                      fontSize: ".9em",
                      fontWeight: 400,
                      display: "inline-flex",
                      alignContent: "flex-start",
                    }}
                  >
                    {item.desc}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stack;
