import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JobHeader from "../components/JobComp/JobHeader";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
export default function Job() {
  const JD = {
    Sales: {
      title: "Sales Executive",
      jobdescription: "I amd for Saler Executive",
      responsibilities: "",
      requirementsAndSkills: "",
      exp: "",
      roleType: "Full Time",
    },
    UI: {
      title: "UI and UX",
      jobdescription:
        "As UX/UI Designer Intern at Helix Smart labs works to create world-class experiences for all our users. You’ll work across Product, Design and Marketing teams to put your talent to work",
      responsibilities:
        "Participate in user research and interaction studies,Translate user stories and business requirements into effective designs.Design sitemap, wireframes, prototypes and UI. Work with Visual Designers to evolve our brand identity style guide. Help instill a human-centered and collaborative culture in the company",
      requirementsAndSkills: "",
      exp: "",
      roleType: "Full Time",
    },
  };
  const params = useParams();
  const tempTitle = params.title.trim().split(" ");
  // console.log(tempTitle)
  console.log(JD[tempTitle[0]].jobdescription);
  const [tempJobDesc, setTempJobDesc] = useState("");
  const [tempJobRes, setTempJobRes] = useState("");
  useEffect(() => {
    setTempJobDesc(JD[tempTitle[0]].jobdescription);
    setTempJobRes(JD[tempTitle[0]].responsibilities);
  }, []);

  return (
    <>
      <Nav />
      <JobHeader title={params.title} roleType={JD[tempTitle[0]].roleType} />

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div>
              <h3
                style={{
                  color: "black",
                  fontFamily: "Light",
                  fontWeight: "bold",
                }}
              >
                Job Description
              </h3>
              <p
                style={{
                  color: "black",
                  fontFamily: "Marcellus",
                  padding: "0px",
                }}
              >
                {tempJobDesc}
              </p>
            </div>
            <div className="mt-5">
              <h3
                style={{
                  color: "black",
                  fontFamily: "Light",
                  fontWeight: "bold",
                }}
              >
                Responsibities
              </h3>
              <ul>
                {tempJobRes.split(".").map((bullet, index) => (
                  <li
                    key={index}
                    style={{
                      color: "black",
                      fontFamily: "Marcellus",
                      padding: "0px",
                      fontSize: "1.3em",
                    }}
                  >
                    {bullet}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-5">
              <h3
                style={{
                  color: "black",
                  fontFamily: "Light",
                  fontWeight: "bold",
                }}
              >
                Requirements And Skills
              </h3>
              <p
                style={{
                  color: "black",
                  fontFamily: "Marcellus",
                  padding: "0px",
                }}
              >
                content
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <h3
                style={{
                  color: "black",
                  fontFamily: "Light",
                  fontWeight: "bold",
                }}
              >
                About HSL
              </h3>
              <p
                style={{
                  color: "black",
                  fontFamily: "Marcellus",
                  padding: "0px",
                }}
              >
                HelixSmartLabs Pvt Ltd (HSL) is a Product-IT Consulting company
                with a significant presence in the Fit. Tech and Ed. Tech
                industries. HSL manages varied portfolios for national and
                international agencies with its competence in customizing Web3,
                Application, and AI-driven solutions. HSL has two offices in
                Delhi NCR, India, and one in Newcastle, United Kingdom. The
                company's client roster includes organizations ranging from
                upcoming startups like BrainAlive Research & TechChefz to
                Government Organizations such as DRDO, National Health
                Services(UK) & NASA(US). Our mission is to make the cutting-edge
                technology of the future accessible to users today.
              </p>
            </div>
            <div className="mt-5">
              <h3 style={{ color: "black", fontFamily: "Light" }}>
                Experience
              </h3>
              <p>content</p>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
}
