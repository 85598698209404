import React from "react";
import Heading from "../Heading";
import QuestionDropdown from "../QuestionDropdown";
const FAQ = () => {

  const list = [
    { ques: "Who are we and what we do?", ans: "HSL is an IT product-based firm that develops innovative solutions for their customers. The company's client roster includes organisations ranging from upcoming startups like BrainAlive Research & TechChefz to Government Organizations such as DRDO, National Health Services(UK) & NASA(US). Our mission is to make the cutting-edge technology of the future accessible to users today." },
    { ques: "Experience with industry?", ans: "At HSL, our extensive experience and expertise in the IT-AI Consultation industry make us the ideal partner for your business. With 3 years of successful operation, we understand the unique challenges and opportunities within the market. Our team of seasoned professionals is dedicated to delivering excellence, ensuring that your project receives personalized attention and tailored solutions. We prioritize open communication and collaboration, working closely with you to bring your vision to life. Our customer-centric approach, coupled with a proven track record of success, empowers your business to thrive in an ever-changing landscape. Choose HelixSmartLabs as your trusted ally and unlock the full potential of your ventures in the dynamic world of digitization." },
    { ques: "Domain experties within the team?", ans: "Our team possesses a wide range of domain expertise, making us a versatile and reliable technology partner. From AI-based solutions to Web3 technologies, we stay at the forefront of cutting-edge innovations. We excel in developing mobile applications, web apps, software, and Chrome plugins, ensuring seamless digital experiences for our clients. With a flair for game development, we create engaging and immersive gaming solutions. Our digital marketing prowess drives successful campaigns, enhancing brand visibility and growth. Above all, we focus on SaaS-based solutions, offering scalable and flexible services tailored to our clients' specific needs. Partnering with us means accessing a dynamic team that delivers exceptional results, leveraging our domain expertise to propel your projects forward in today's ever-changing tech landscape." },
    { ques: "Technology we are working on?", ans: "We thrive on innovation and stay ahead in the fast-paced tech world by harnessing a wide spectrum of cutting-edge technologies. Our diverse expertise includes AI and ML, empowering us to create intelligent systems and data-driven solutions that optimize processes and deliver valuable insights. With a strong foundation in blockchain and Web3 technologies, we build decentralized applications and implement secure, transparent solutions for digital asset management and other novel use cases. We excel in mobile application development, crafting high-performance apps for Android and iOS platforms that deliver seamless user experiences. Additionally, our web application development prowess ensures the creation of feature-rich, scalable solutions tailored to unique client requirements. As software specialists, we design custom solutions that address specific challenges across various domains. Our Chrome plugins streamline workflows and enhance productivity, seamlessly integrating with browsers to meet user needs effectively. Furthermore, our game development team creates captivating and immersive gaming experiences, spanning diverse genres and platforms. With a focus on SaaS-based solutions, we offer scalable software services that cater to clients' evolving needs. Leveraging digital marketing strategies, we boost brand visibility, generate leads, and foster customer engagement. Exploring IoT technologies, we develop smart, interconnected devices and systems that optimize processes and enable automation across industries. At HSL, we are committed to delivering cutting-edge solutions that drive business growth and success in an ever-evolving technological landscape." },
    { ques: "Demography of HSL with the clients?", ans: "At HSL, our client base reflects a harmonious blend of 40% international clients and 60% esteemed clients from India. Our global reach underscores our commitment to delivering innovative solutions across borders, while our strong presence in the Indian market showcases our deep understanding of local needs. As potential clients, you'll benefit from our diverse experience and proficiency in serving a wide array of customers worldwide. We understand the significance of cultural nuances and language preferences, ensuring that our solutions resonate with your specific requirements. Whether you are based in India or anywhere else around the globe, partnering with HSL opens doors to unparalleled opportunities, backed by our exceptional track record and dedication to exceeding your expectations. Join us on this transformative journey, where our expertise and adaptability will pave the way for your business success, wherever your ambitions may lead." }
  ];
  return (
    <div
      className="row pb-5"
      style={{
        backgroundColor: "#141414",
        textAlign: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <Heading
        heading="Creating the Everlasting Experience"
        tagline="Why Work with us"
      />
      <div style={{ padding: "20px 10%" }}>
        {list.map((item, index) => (
          <QuestionDropdown key={index} ques={item.ques} ans={item.ans} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
