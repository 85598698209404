import React from "react";

const CardContainer = (props) => {
  const { cardList = [] } = props;
  return (
    <div className="row" style={{ padding: 0, margin: 0 }}>
      {cardList.map((item, index) => (
        <div key={index} className="col-lg-4">
          <div className="variantCard">
            <img src={item.image} alt="can't load" width="100%" />
            <div className="variantContent">
              <h4 className="pt-5">{item.heading}</h4>
              <p className="px-4" style={{ textAlign: "left" }}>
                {item.text.length > 100
                  ? `${item.text.slice(0, 100)}...`
                  : item.text}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="/contact-us"
                style={{ textDecoration: "none", color: "white" }}
              >
                <button
                  type="button"
                  className="button"
                  style={{ minWidth: "8em", marginBottom: -15 }}
                >
                  <span style={{ fontFamily: "Marcellus" }}>Shop Now</span>
                  <span className="material-icons-outlined">
                    arrow_forward_ios
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardContainer;
