import React from 'react'

export default function CareerHeader() {
  return (
    <div className="row" style={{ padding: 0, margin: 0 }}>
      <div className="col-xl-6 leftHeadCareer" style={{ padding: 0, margin: 0 }}>
        <div className="layerCareer">
          <div
            className="insideLeft"
            style={{ marginLeft: 25, marginRight: 15 }}
          >
            <h1 style={{ color: "white", fontSize: "3rem" }}>
              Careers
            </h1>
            <div>
              <p style={{ fontSize: "1.4rem" }}>
                We love conversation on everything 
                <br/>
                Technology and otherwise
                
              </p>
            </div>
            {/* <div>
              <button
                onClick={() => {
                  console.log("hii", document.getElementById("coverAllNeeds"));
                  document
                    .getElementById("coverAllNeeds")
                    .scrollIntoView({ behavior: "smooth" }, true);
                }}
                className="button"
                style={{ minWidth: "10em" }}
              >
                <span>Explore</span>
                <span>
                  <b>&gt;</b>
                </span>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      
      <div className="col-xl-6 career-right" style={{ padding: 0, margin: 0 }}>
      {/* <div className='career-gradient'></div> */}
      </div>
    
    </div>
  );
  
}
