import React from "react";

const ItServiceHeader = () => {
  return (
    <div
      className="row ItLeft"
      style={{ padding: 0, margin: 0, color: "white" }}
    >
      <div
        className="col-xl-6"
        style={{ padding: 0, margin: 0, background: "black" }}
      >
        <div className="layer">
          <div
            className="insideLeft"
            style={{ marginLeft: 25, marginRight: 15 }}
          >
            <h1
              style={{ color: "white", fontSize: "3rem", fontFamily: "Light" }}
            >
              IT Solutions
            </h1>
            <p style={{ fontSize: "1.4rem", fontFamily: "Marcellus" }}>
              We at HSL believe in bringing change in technology which can{" "}
              <br /> truly impact the lives
            </p>
            <div>
              <button
                onClick={() => {
                  console.log("hii", document.getElementById("coverAllNeeds"));
                  document
                    .getElementById("coverAllNeeds")
                    .scrollIntoView({ behavior: "smooth" }, true);
                }}
                className="button"
                style={{ minWidth: "10em" }}
              >
                <span style={{ fontFamily: "Marcellus" }}>Explore</span>
                <span>
                  {/* <b>&gt;</b> */}
                  <i className="arrow right-arrow"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 ItRight" style={{ padding: 0, margin: 0 }}></div>
    </div>
  );
};

export default ItServiceHeader;
