import React from "react";

const OemHeader = () => {
  return (
    <div
      className="row OemLeft"
      style={{ padding: 0, margin: 0, color: "white" }}
    >
      <div className="col-xl-6" style={{ padding: 0, margin: 0 }}>
        <div className="insideLeft" style={{ marginLeft: 25, marginRight: 15 }}>
          <h1 style={{ fontSize: "3rem" }}>
            Take ultimate
            <br />
            control of your home
          </h1>
          <p>
            We at HSL believe in bringing change in technology which can truly
            impact the lives
          </p>
          <div>
            <button
              onClick={() => {
                console.log("hii", document.getElementById("coverAllNeeds"));
                document
                  .getElementById("coverAllNeeds")
                  .scrollIntoView({ behavior: "smooth" }, true);
              }}
              className="button"
              style={{ minWidth: "10em" }}
            >
              <span>Explore</span>
              <span>
                {/* <b>&gt;</b> */}
                <i className="arrow right-arrow"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 OemRight"
        style={{ padding: 0, margin: 0 }}
      ></div>
    </div>
  );
};

export default OemHeader;
