import React from "react";
import Logo from "../assets/fitreat/logo.svg";

import HeaderImage from "../assets/fitreat/header.png";

import "./fit.css";

function Header() {
  return (
    <div className="row" style={{paddingTop:170}}>
      <div
        data-aos="zoom-out"
        data-aos-duration="2000"
        className="col-md-5 flex_row_center_center header_logo"
      >
        <img src={Logo} alt="Fitreat" style={{ width: "60%" }} />
      </div>
      <div
        data-aos="zoom-out"
        data-aos-duration="2000"
        className="col-md-7 flex_row_center_center "
      >
        <img src={HeaderImage} alt="header" style={{ width: "100%" }} />
      </div>
    </div>
  );
}

export default Header;
