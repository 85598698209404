import React, { useEffect, useState } from "react";
import footerlogo from "../assets/IMG-4.png";
import QuestionDropdown from "./QuestionDropdown";
const Footer = () => {
  const handleEmail = (e) => {
    e.preventDefault();
  };
  let date = new Date();
  let year = date.getFullYear();

  const [mobile, setmobile] = useState(false);

  useEffect(() => {
    const screenSize = window.screen.width;

    if (screenSize < 800) {
      setmobile(true);
    } else {
      setmobile(false);
    }

    return () => {};
  }, []);

  const list = [
    { ques: "Product", ans: "Air Touch" },
    {
      ques: "Services",
      ans: `IT Solutions
      AI Solutions
      Product Dev.`,
    },
    {
      ques: "Quick Links",
      ans: `About Us
      Contact Us
      Instant Quote`,
    },
  ];

  return (
    //removed p-5 in className=row
    <div
      className="container-fluid"
      style={{ backgroundColor: "#141414", padding: 0, margin: 0 }}
    >
      <div
        className="row pt-5 pb-0 container"
        style={{ margin: "auto", display: "block" }}
      >
        <div className="col">
          <div className="row">
            <div className="col-lg-3 pt-1 col-md-4">
              {/* <div style={{ display: "flex" }}>
                <img
                  style={{ height: "58px" }}
                  alt="helixSmartlabs"
                  src={footerlogo}
                />
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: !mobile ? "left" : "center",
                }}
              >
                <img
                  style={{ height: "58px" }}
                  alt="helixSmartlabs"
                  src={footerlogo}
                />
              </div>
              <div
                className="py-2 px-3"
                style={{
                  display: "block",
                  margin: "auto",
                  textAlign: !mobile ? "left" : "center",
                  fontFamily: "Marcellus",
                }}
              >
                Manav Rachna Campus
                <br />
                Sector - 46, Faridabad
                <br />
                Haryana, India - 121006
                <br />
                Mobile: +91 9873932539
                <br />
                contact@helixsmartlabs.in
              </div>
            </div>
            {!mobile && (
              <div className="col-lg-2  offset-lg-1 pt-1 col-md-4">
                <div
                  className="py-2"
                  style={{
                    display: "block",
                    margin: "auto",
                    textAlign: !mobile ? "left" : "center",
                  }}
                >
                  <h5 style={{ fontFamily: "Light" }}>Products</h5>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/products"
                  >
                    <span>Air Touch</span>
                  </a>
                </div>
                <div
                  className="py-2 "
                  style={{
                    display: "block",
                    margin: "auto",
                    textAlign: !mobile ? "left" : "center",
                  }}
                >
                  <h5 style={{ fontFamily: "Light" }}>Services</h5>

                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/services"
                  >
                    <span style={{ fontFamily: "Marcellus" }}>
                      IT Solutions
                    </span>
                  </a>
                  <br />
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/AI-Services"
                  >
                    <span style={{ fontFamily: "Marcellus" }}>
                      AI Solutions
                    </span>
                  </a>
                  <br />
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/Oem-Services"
                  >
                    <span style={{ fontFamily: "Marcellus" }}>
                      Product Dev.
                    </span>
                  </a>
                </div>
              </div>
            )}
            {!mobile && (
              <div className="col-lg-2 pt-1 col-md-4">
                <div
                  className="py-2 "
                  style={{
                    display: "block",
                    margin: "auto",
                    textAlign: !mobile ? "left" : "center",
                  }}
                >
                  <h5 style={{ fontFamily: "Light" }}>Quick Links</h5>
                  <a
                    href="/"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <span style={{ fontFamily: "Marcellus" }}>About Us</span>
                  </a>
                  <br />
                  <a
                    href="/careers"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Marcellus",
                      color: "white",
                    }}
                  >
                    <span>Careers</span>
                  </a>
                  <br />
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/contact-us"
                  >
                    <span style={{ fontFamily: "Marcellus" }}>Contact Us</span>
                  </a>
                  <br />
                  <span>
                    <a
                      href="/contact-us"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontFamily: "Marcellus",
                      }}
                    >
                      Instant Quote
                    </a>
                  </span>
                </div>
              </div>
            )}
            {mobile && (
              <div style={{ padding: "20px 10%" }}>
                {list.map((item, index) => (
                  <QuestionDropdown
                    key={index}
                    ques={item.ques}
                    ans={item.ans}
                  />
                ))}
              </div>
            )}

            <div className="col-lg-4 col-md-12  pt-2 mt-1">
              <div className="p-4" style={{ backgroundColor: "#34b6dd" }}>
                <span>
                  Interested in getting updates on our new <u>products</u>,
                  services, discounts etc.
                </span>
                <h5 className="py-1" style={{ fontWeight: 800 }}>
                  Subscribe to our Newsletter
                </h5>
                <form
                  style={{ display: "flex", justifyContent: "space-between" }}
                  onSubmit={(e) => handleEmail(e)}
                >
                  <input
                    style={{ width: "80%" }}
                    placeholder="Email Address"
                    type="email"
                    className="form-control"
                  />
                  <button className="buttonDark" type="submit">
                    <i className="material-icons-outlined">arrow_forward_ios</i>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <hr style={{ width: "100%" }} />
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-md-4" style={{ fontFamily: "Marcellus" }}>
              HelixSmartLabs Private Limited
            </div>
            <div className="col-md-4">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="p-2">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://www.facebook.com/HelixSmartLabs"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </div>
                <div className="p-2">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://www.linkedin.com/company/helix-smart-labs-pvt-ltd/"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
                <div className="p-2">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://www.instagram.com/helixsmartlabs/"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 copyright"
              style={{ fontFamily: "Marcellus" }}
            >
              {" "}
              &#169;{year}, All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
