import React from 'react';

const Heading = (props) => {
    return (
        <div id='coverAlleeds'>
            <h2 style={{fontFamily:'Marcellus'}} className="mt-5 py-2 center-heading">{props.heading}</h2>
            <h1 style={{fontFamily:'Light'}} className="py-2">{props.tagline}</h1>
        </div>
    );
};

export default Heading;