import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";

const Nav = (props) => {
  const [colorChange, setColorchange] = useState(false);
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [dropProduct, setDropProduct] = useState(true);
  const [dropService, setDropService] = useState(true);

  const changeNavbarColor = () => {
    setMenuVisibility(false);
    if (window.scrollY) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  const clickHandlerProduct = () => {
    if (dropProduct === false) {
      setDropProduct(true);
    } else {
      setDropProduct(false);
    }
  };
  const clickHandlerService = () => {
    if (dropService === false) {
      setDropService(true);
    } else {
      setDropService(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
  });
  return (
    <div
      className="fixed-top"
      style={{
        backgroundColor: colorChange ? "rgba(0, 0, 0, .9)" : "transparent",
        padding: "1em",
      }}
    >
      <div className="topbar">
        <div>
          <a href="/">
            <img src={logo} alt="Logo" style={{ width: 180 }} />
          </a>
        </div>
        <div
          className="MenuButton"
          onClick={() => setMenuVisibility(!menuVisibility)}
        >
          <span className="material-icons-outlined">menu</span>
        </div>
        <div className="topbar-links" style={{ marginRight: "1em" }}>
          <a
            id="links"
            style={{
              fontFamily: "Marcellus",
              textDecoration: props.textDecoHome,
            }}
            href="/"
            className="nav-link"
          >
            Home
          </a>
          {/* <Link
            style={{ fontFamily: "Marcellus" }}
            to="/products"
            className="nav-link"
          >
            Products
          </Link> */}
          <div className="dropdown">
            <span
              style={{
                fontFamily: "Marcellus",
                textDecoration: props.textDecoProduct,
              }}
            >
              Products
            </span>
            <div className="dropdown-content">
              <a
                className="nav-link"
                href="/products"
                style={{ color: "white", display: "block" }}
              >
                Air Touch
              </a>

              <NavLink
                to="/fitreat" href="#" style={{ color: "white", display: "block" }}>
                Fitreat.AI
              </NavLink>
            </div>
          </div>
          <div className="dropdown">
            <span
              style={{
                fontFamily: "Marcellus",
                textDecoration: props.textDecoService,
              }}
            >
              Services
            </span>
            <div className="dropdown-content">
              <NavLink
                to="/services"
                style={{ color: "white", display: "block" }}
              >
                IT Service
              </NavLink>
              <NavLink
                to="/Oem-Services"
                style={{ color: "white", display: "block" }}
              >
                Oem Service
              </NavLink>
              <NavLink
                to="/AI-Services"
                style={{ color: "white", display: "block" }}
              >
                AI Service
              </NavLink>
            </div>
          </div>

          {/* <Link
            style={{ fontFamily: "Marcellus" }}
            to="/services"
            className="nav-link"
          >
            Services
          </Link> */}
          <span
            style={{ fontFamily: "Marcellus", cursor: "pointer" }}
            onClick={() => {
              // console.log("hi");
              if (window.location.pathname !== "/") {
                window.location.pathname = "/";
              }
              document.getElementById("team").scrollIntoView();
              // console.log(element);
              // window.scrollIntoView(element);
            }}
            className="nav-link"
          >
            About Us
          </span>
          <a
            style={{ fontFamily: "Marcellus" }}
            href="/contact-us"
            className="button"
          >
            Contact Us
          </a>
        </div>
      </div>
      <div
        className="submenu mt-2"
        style={{
          marginRight: "1em",
          display: menuVisibility ? "flex" : "none",
        }}
      >
        <div>
          <a href="/" className="nav-link active">
            Home
          </a>
        </div>
        <div onClick={clickHandlerProduct}>
          <a href="/products">
            Products{" "}
            <span>
              <i class="arrow down"></i>
            </span>
          </a>
          <a
            style={{ paddingLeft: "60px" }}
            hidden={dropProduct}
            href="/products"
          >
            Air Touch
          </a>
          <a style={{ paddingLeft: "60px" }} hidden={dropProduct} href="/fitreat">
            Fitreat.Ai
          </a>
          {/* <li className="dropdown">
            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Products</a>
            <div className="dropdown-menu" style={{backgroundColor:'#020202'}}>
              <a className="dropdown-item" href="/products">Air Touch</a>
              <a className="dropdown-item" href="#">Fitreat.Ai</a>
            </div>
          </li> */}
        </div>
        <div onClick={clickHandlerService}>
          <a href="/services" className="nav-link">
            Services{" "}
            <span>
              <i class="arrow down"></i>
            </span>
          </a>
          <a
            style={{ paddingLeft: "60px" }}
            hidden={dropService}
            href="/services"
          >
            IT Service
          </a>
          <a
            style={{ paddingLeft: "60px" }}
            hidden={dropService}
            href="/Oem-Services"
          >
            Oem Service
          </a>
          <a
            style={{ paddingLeft: "60px" }}
            hidden={dropService}
            href="/AI-Services"
          >
            AI Service
          </a>
        </div>
        <div>
          <a href="/contact-us" className="button">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Nav;
