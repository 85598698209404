import React from "react";
import echo from "../../assets/echo.png";
const ReliabiltyBann = () => {
  return (
    <div className="row" style={{ padding: 0, margin: 0, color: "black" }}>
      <div className="col-lg-6" style={{ padding: 0, margin: 0 }}>
        <img src={echo} alt="can't be loaded" width="100%" />
      </div>
      <div
        className="col-lg-6"
        style={{
          padding: 45,
          margin: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div>
          <span className="bannerHeading mt-5 pt-5" style={{ color: "black",fontFamily:'Marcellus' }}>
            Trust Us
          </span>
          <h1 style={{fontFamily:'Light'}}>Control with Reliablity</h1>
          <p style={{fontFamily:'Marcellus'}}>
            Control your home with home assistants like Google Home, Alexa and
            Siri, so no matter where you are or what you are doing, you are in
            control.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReliabiltyBann;
